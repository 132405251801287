.site_container {

    .sections {
        padding: 2vw 3vw;

        .section_titles {
            text-align: center;
            font-size: 22px;
        }
    }
}