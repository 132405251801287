.projects_section {
    
    .stickied, .stickied a {
        font-size: 24px;
    }

    .mini_projects_container {

        .mini_project_container {

            .mini_project_info {

                .project_meta_a p {
                    width: 25vw;
                }
            }
        }
    }
}