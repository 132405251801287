.modal {
    width: 100%;
    height: 100%;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(10px);
    background: rgba($color: #000000, $alpha: .8);

    a, a:visited {
        font-size: 50px;
        line-height: 120px;
        color: #fff;
    }
}