.skills_section {

    .skills_list {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        justify-content: center;
        align-content: center;

        .skill_container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .skill_img {
                max-height: 50px;
            }
        }
    }
}