.projects_section {

    .project_container, .mini_projects_container {

        .project_imgs_container, .project_img_container {
    
            .modal {
                align-items: center;
    
                .modal_project_imgs {
                    width: 75%;
                    max-width: 700px;
                }
            }
        }
    }

    .mini_projects_container {

        .mini_project_container {

            .mini_project_info {

                .project_meta {

                    .project_meta_a p {
                        width: 250px;
                    }
                }
            }
        }
    }
}