// // abstracts, vendors

@import
    './base/normalize',
    './base/typography';

@import 
    './layout/app',
    './layout/header',
    './layout/menu',
    './layout/about',
    './layout/skills',
    './layout/projects',
    './layout/contact';

@import
    './themes/theme';

@import
    './components/modal';

// // pages, themes

// media queries
@media only screen and (min-width: 650px) {
    @import 
        './layout/app_medium',
        './layout/header_medium',
        './layout/menu_medium',
        './layout/about_medium',
        './layout/skills_medium',
        './layout/projects_medium';
}

@media only screen and (min-width: 850px) {
    @import
        './layout/app_large',
        './layout/_header_large',
        './layout/about_large',
        './layout/skills_large',
        './layout/projects_large';

    @import
        './themes/theme_large';
}

@media only screen and (min-width: 1050px) {
    @import
        './layout/app_xlarge',
        './layout/_menu_xlarge',
        './layout/projects_xlarge';

    @import
        './themes/theme_xlarge';
}