.site_nav {

    .modal_activator_img {
        display: block;
        cursor: pointer;
        user-select: none;
    }

    .horizontal_menu {
        display: none;
    }
}