.site_container {
    width: 850px;
    margin: 0 auto;
    padding: 0 5px;

    .sections {
        padding: 1vw 0;

        p, a, li, figcaption {
            font-size: 24px;
            line-height: 32px;
        }

        .section_titles {
            font-size: 28px;
        }
    }
}