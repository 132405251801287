.about_section {

    .salutation_container {
        flex-direction: row-reverse;
        justify-content: space-around;

        .profile_img {
            width: 50%;
        }
    }
}