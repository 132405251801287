.about_section {

    span {
        font-size: .5em;
        font-weight: 900;
        vertical-align: super;
    }
    
    .salutation_container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .salutation {
            font-size: calc(16px + 2vw);
        }
    
        .profile_img {
            width: 75%;
            max-width: 350px;
            border-radius: 50%;
        }
    }
}